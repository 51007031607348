"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.symbol.to-primitive.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.date.to-primitive.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.regexp.test.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accIdsFromLink = accIdsFromLink;
exports.registerAdClickEvent = registerAdClickEvent;
exports.registerLinkClickEvents = registerLinkClickEvents;
exports.renderBreadcrumb = renderBreadcrumb;
exports.renderMatchCode = renderMatchCode;
exports.renderPartnerDashboardButtons = renderPartnerDashboardButtons;
exports.renderPixelCode = renderPixelCode;
exports.renderPixelForUrl = renderPixelForUrl;
exports.renderTtdMatch = renderTtdMatch;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url-search-params.js");
var _utilities = require("../helpers/utilities");
var _fetch = require("../helpers/fetch");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function getQueryParamArray(params) {
  var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var doc = window.document;
  var queryParams = [];
  var dataPoints = ['cb', 'a_id', 'p_id', 'e_id', 'f_id', 'lp_id', 'plp_id', 'cpn_id', 'pcpn_id', 'crv_id', 't_id', 'tag_id', 'seg_id', 'ses_id', 'ttd_id', 'adx_anon', 'adx_vendor', 'rfr', 'flvr', 'loc_url', 'trgt', 'form_type', 'form_id', 'msg', 'q_id', 's_w', 's_h', 'b_w', 'b_h', 'cust_params', 'utm_params', 'debug', 'bypass_hash', 'fcv', 'pp_id', 'email_addr'];
  var utmDataPoints = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  var customParametersEncoded = (0, _utilities.encodeCustomParams)(dataPoints, params, true);
  var parsedUri = (0, _utilities.parseUri)(doc.location.href);
  var utmParamsEncoded = (0, _utilities.encodeUtmParams)(utmDataPoints, parsedUri.queryKey);
  var msgEncoded = (0, _utilities.encodeMessage)(params.msg);
  var valueEncoded = (0, _utilities.encodeValue)(params.fcv);
  dataPoints.forEach(function (key) {
    switch (key) {
      case 'cb':
        queryParams.push([key, Math.random().toString(16).slice(2)]);
        break;
      case 'a_id':
      case 'f_id':
      case 'lp_id':
      case 'plp_id':
      case 'seg_id':
      case 'crv_id':
      case 't_id':
      case 'ses_id':
      case 'form_type':
      case 'form_id':
      case 'debug':
      case 'q_id':
      case 'bypass_hash':
        if (params[key]) {
          queryParams.push([key, params[key]]);
        }
        break;
      case 'p_id':
      case 'e_id':
      case 'cpn_id':
      case 'tag_id':
      case 'pcpn_id':
      case 'pp_id':
      case 'email_addr':
        if (params[key]) {
          queryParams.push([key, params[key]]);
        } else if (parsedUri.queryKey && parsedUri.queryKey[key]) {
          queryParams.push([key, parsedUri.queryKey[key]]);
        }
        break;
      case 'ttd_id':
        if (state.trackingMode === 'ad' && parsedUri.queryKey[key]) {
          queryParams.push([key, parsedUri.queryKey[key]]);
        } else if (params[key]) {
          queryParams.push([key, params[key]]);
        }
        break;
      case 'adx_anon':
      case 'adx_vendor':
        if (state.trackingMode === 'ad' && parsedUri.queryKey[key]) {
          queryParams.push([key, encodeURIComponent(parsedUri.queryKey[key])]);
        }
        break;
      case 'flvr':
        if (params.flvr) {
          queryParams.push([key, params.flvr]);
        } else if (state.trackingMode === 'ad') {
          queryParams.push([key, 'ad_view']);
        } else {
          queryParams.push([key, 'page_view']);
        }
        break;
      case 'rfr':
        if (params.rfr) {
          queryParams.push([key, encodeURIComponent(params.rfr)]);
        } else if (doc.referrer) {
          queryParams.push([key, encodeURIComponent(doc.referrer)]);
        }
        break;
      case 'loc_url':
        if (state.trackingMode === 'ad' && parsedUri.queryKey.loc_url) {
          queryParams.push([key, encodeURIComponent(parsedUri.queryKey.loc_url)]);
        } else if (params.loc_url) {
          queryParams.push([key, encodeURIComponent(params.loc_url)]);
        } else if (doc.location.href) {
          queryParams.push([key, encodeURIComponent(doc.location.href)]);
        }
        break;
      case 'trgt':
        if (params.trgt) {
          queryParams.push([key, encodeURIComponent(params.trgt)]);
        }
        break;
      case 's_w':
        if (params.s_w) {
          queryParams.push([key, params.s_w]);
        } else {
          queryParams.push([key, window.screen_width]);
        }
        break;
      case 's_h':
        if (params.s_h) {
          queryParams.push([key, params.s_h]);
        } else {
          queryParams.push([key, window.screen_height]);
        }
        break;
      case 'b_w':
        if (params.b_w) {
          queryParams.push([key, params.b_w]);
        } else {
          queryParams.push([key, window.browser_width]);
        }
        break;
      case 'b_h':
        if (params.b_h) {
          queryParams.push([key, params.b_h]);
        } else {
          queryParams.push([key, window.browser_height]);
        }
        break;
      case 'cust_params':
        if (customParametersEncoded) {
          queryParams.push([key, customParametersEncoded]);
        }
        break;
      case 'utm_params':
        if (utmParamsEncoded) {
          queryParams.push([key, utmParamsEncoded]);
        }
        break;
      case 'msg':
        if (msgEncoded) {
          queryParams.push([key, msgEncoded]);
        }
        break;
      case 'fcv':
        if (valueEncoded) {
          queryParams.push([key, valueEncoded]);
        }
        break;
      default:
        break;
    }
  });
  return queryParams;
}
function crumbOnError() {
  // verify that localStorage is available before calling crumbOnError
  // if called and localStorage is not available, will raise an exception
  var crumbs = localStorage.getItem('failedCrumbs');
  var parsedCrumbs = crumbs ? JSON.parse(crumbs) : [];
  localStorage.setItem('failedCrumbs', JSON.stringify([this.src].concat(_toConsumableArray(parsedCrumbs))));
}
function storageAvailable() {
  // you have to actually reach into localStorage
  // to verify if the browser allows using it
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
  try {
    var storage = window.localStorage;
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}
function render(src, onload) {
  var _head;
  var failedCrumbsRaw;
  var storageIsAvailable = storageAvailable();
  if (storageIsAvailable) {
    failedCrumbsRaw = localStorage.getItem('failedCrumbs');
    // remove the errors from the cache
    // they will be re-added in the error handler if they fail again
    localStorage.removeItem('failedCrumbs');
  }
  var failedCrumbsParsed = failedCrumbsRaw ? JSON.parse(failedCrumbsRaw) : [];
  (0, _utilities.log)(failedCrumbsParsed.length > 0 ? "Append img elements: ".concat([src].concat(_toConsumableArray(failedCrumbsParsed)).join(', ')) : "Append img element: ".concat(src));
  function generateElements(crumbs) {
    return crumbs.map(function (crumbSrc) {
      // set width and height to 0 by default
      var img = new window.Image(0, 0);
      if (onload) {
        img.onload = onload;
      }
      if (storageIsAvailable) {
        img.onerror = crumbOnError;
      }
      img.src = crumbSrc;
      img.setAttribute('border', '0');
      img.alt = '';
      return img;
    });
  }
  (_head = (0, _utilities.head)()).append.apply(_head, _toConsumableArray(generateElements([src].concat(_toConsumableArray(failedCrumbsParsed)))));
}
function renderMatchCode() {
  var devMode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var pixelKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'feathr';
  var accountId = window[pixelKey] && window[pixelKey].accountId || window.feathr_account_id;
  if (accountId === undefined) {
    return null;
  }
  (0, _utilities.log)({
    level: 'log',
    message: 'renderMatchCode()'
  });
  var query = new URLSearchParams({
    a_id: accountId,
    pk: pixelKey
  });
  var matchScriptSrc = "".concat((0, _utilities.getPoloUrl)('/analytics/match/script.js', devMode), "?").concat(query.toString());
  var matchScriptElement = window.document.createElement('script');
  matchScriptElement.setAttribute('async', 'true');
  matchScriptElement.type = 'text/javascript';
  (0, _utilities.addOnLoadEventListener)(function () {
    matchScriptElement.src = matchScriptSrc;
    (0, _utilities.head)().appendChild(matchScriptElement);
  }, true);
  return matchScriptElement;
}
function renderPixelCode(accountId) {
  var devMode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var pixelKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'feathr';
  if (accountId === undefined) {
    return null;
  }
  (0, _utilities.log)("renderPixelCode(feathrAccountId=".concat(accountId, ")"));
  var pixelElement = window.document.createElement('script');
  pixelElement.setAttribute('async', 'true');
  pixelElement.type = 'text/javascript';
  return (0, _utilities.addOnLoadEventListener)(function () {
    (0, _utilities.log)('renderPixelCode load event callback');
    pixelElement.src = "".concat((0, _utilities.getPoloUrl)("/accounts/".concat(accountId, "/pixel.js"), devMode), "?pk=").concat(pixelKey);
    (0, _utilities.head)().appendChild(pixelElement);
  }, true);
}
function renderBreadcrumb(params) {
  var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    devMode: false
  };
  (0, _utilities.log)("Render_breadcrumb with params ".concat(JSON.stringify(params)));
  if (params.a_id === undefined) {
    (0, _utilities.log)('Undefined account id in renderBreadcrumb params');
    return null;
  }
  var crumb = (0, _utilities.getPoloUrl)('/analytics/crumb', state.devMode);
  var queryParams = getQueryParamArray(params, state);
  var crumbUrl = (0, _utilities.buildUrl)(crumb, queryParams);
  (0, _utilities.log)("Adding crumb with url: ".concat(crumbUrl));
  return (0, _utilities.addOnLoadEventListener)(function () {
    render(crumbUrl);
  }, true);
}
function accIdsFromLink(link) {
  var acIds = link.getAttribute('data-feathr-link-aids');
  return acIds ? acIds.split(',') : [];
}
function registerLinkClickEvents(params) {
  var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    devMode: false,
    clickFlavor: 'page_link_click'
  };
  if (!window.document || !params.a_id) return;
  (0, _utilities.log)("register link click events with params: ".concat(JSON.stringify(params)));
  var links = _toConsumableArray(window.document.getElementsByTagName('a'));
  links.forEach(function (link) {
    if (!link.getAttribute('data-feathr-click-track')) {
      (0, _utilities.addElementEventListener)(link, 'click', function pageLinkClick(event) {
        var href = '';
        if (event.target && event.target.href) {
          href = event.target.href;
        } else if (this && this.href) {
          href = this.href;
        } else {
          (0, _utilities.log)('error', 'Tried to track click, but no href was available.');
        }
        var flvr = state.clickFlavor || 'page_link_click';
        if (event.target && event.target.getAttribute('data-feathr-click-flvr')) {
          flvr = event.target.getAttribute('data-feathr-click-flvr');
        }
        var clickParams = _objectSpread(_objectSpread({}, params), {}, {
          flvr: flvr,
          trgt: href
        });
        (0, _utilities.log)("render link click breadcrumb: ".concat(JSON.stringify(clickParams)));
        accIdsFromLink(link).forEach(function (id) {
          clickParams.a_id = id;
          renderBreadcrumb(clickParams, state);
        });
      });
      link.setAttribute('data-feathr-click-track', true);
    }
    var linkAcIds = accIdsFromLink(link);
    if (!linkAcIds.includes(params.a_id)) {
      linkAcIds.push(params.a_id);
    }
    link.setAttribute('data-feathr-link-aids', linkAcIds.join(','));
  });
}
function registerAdClickEvent(params, state) {
  var clickAreas = _toConsumableArray(document.getElementsByClassName('feathrcrv-click-area'));
  clickAreas.forEach(function (area) {
    (0, _utilities.addElementEventListener)(area, 'click', function (event) {
      event.preventDefault();
      var clickParams = _objectSpread(_objectSpread({}, params), {}, {
        trgt: state.destinationUrl,
        flvr: 'ad_click'
      });
      var crumb = (0, _utilities.getPoloUrl)('/analytics/crumb', state.devMode);
      var queryParams = getQueryParamArray(clickParams, state);
      var crumbUrl = (0, _utilities.buildUrl)(crumb, queryParams);
      var parsedUri = (0, _utilities.parseUri)(document.location.href);
      var ttdClk = parsedUri.queryKey.ttd_clk;
      var href = "".concat(crumbUrl, "&rdr=").concat(ttdClk || '').concat(state.destinationUrl);
      window.open(href);
    });
  });
}
function renderPixelForUrl(refUrl, pixelSrc) {
  if (refUrl === undefined || pixelSrc === undefined) {
    return null;
  }
  // Check if the current location *matches* the refUrl
  var match = (0, _utilities.checkUriMatch)(document.location.href, refUrl);
  if (match) {
    (0, _utilities.log)("Rendering pixel for url after successful match. Ref:".concat(refUrl, ", Test: ").concat(document.location.href));
    return (0, _utilities.addOnLoadEventListener)(function () {
      render(pixelSrc);
    }, true);
  }
  return null;
}
function renderTtdMatch(fId, pixelKey) {
  // If we don't have a match record for this user, add a match pixel for TTD
  // so we get redirected with their ttd_id
  function rerenderMatchCode() {
    renderMatchCode(false, pixelKey);
  }
  if (fId === undefined) return null;
  (0, _utilities.log)("Adding ttd match pixel for f_id ".concat(fId));
  return (0, _utilities.addOnLoadEventListener)(function () {
    render((0, _utilities.ttdMatchUrl)(fId, window[pixelKey].accountId), rerenderMatchCode);
  }, true);
}
function renderLoadedStateForWidget(element) {
  var loaded = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var responseData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  // eslint-disable-next-line
  element.dataset.state = loaded ? 'loaded' : 'loading';
  // Clear existing contents;
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
  var dashboardAnchorElement = document.createElement('a');
  dashboardAnchorElement.className = 'feathr-dashboard-anchor';
  dashboardAnchorElement.title = 'Click here to access your Marketing Material!';
  dashboardAnchorElement.style.border = '1px solid #eee';
  dashboardAnchorElement.style.borderRadius = '4px';
  dashboardAnchorElement.style.padding = '10px 20px';
  dashboardAnchorElement.style.background = '#57a9e3';
  dashboardAnchorElement.style.color = '#fff';
  dashboardAnchorElement.style.textDecoration = 'none';
  dashboardAnchorElement.style.boxShadow = '0px 8px 15px rgba(0, 0, 0, 0.1)';
  dashboardAnchorElement.style.transition = 'all 0.3s ease 0s';
  dashboardAnchorElement.style.display = 'inline-block';
  if (loaded) {
    dashboardAnchorElement.href = responseData.dashboard_url;
    dashboardAnchorElement.target = '_blank';
  }
  var loadedSpan = document.createElement('span');
  loadedSpan.style.fontFamily = '"Lato", sans-serif';
  loadedSpan.style.fontSize = '14px';
  loadedSpan.style.fontWeight = '500';
  loadedSpan.style.textTransform = 'uppercase';
  loadedSpan.style.letterSpacing = '2.5px';
  loadedSpan.style.display = 'inline-block';
  loadedSpan.innerText = 'Loading...';
  dashboardAnchorElement.appendChild(loadedSpan);
  if (loaded) {
    var logo = document.createElement('img');
    logo.src = 'https://s3.amazonaws.com/feathr-api-pictures/feathr_mark_light_sm.png';
    logo.height = 25;
    logo.width = 14;
    logo.style.marginRight = '14px';
    logo.style.verticalAlign = 'middle';
    dashboardAnchorElement.insertBefore(logo, loadedSpan);
    loadedSpan.innerText = 'Access Dashboard';
  }
  element.appendChild(dashboardAnchorElement);
}

/**
 * Add space between camelCase text.
 */
function unCamelCase(str) {
  var newStr = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
  newStr = newStr.toLowerCase(); // add space between camelCase text
  return newStr;
}
function cleanCustomDataKey(key) {
  if (key.indexOf('partnerCustom') === -1) {
    return key;
  }
  var newKey = key.replace('partnerCustom', '');
  newKey = unCamelCase(newKey);
  newKey = newKey.split(' ').join('_').toUpperCase();
  return newKey;
}
function renderPartnerDashboardButton(element) {
  (0, _utilities.log)("Rendering Partner Dashboard Widget for element ".concat(element));
  var campaignID = element.dataset.campaignId;
  var data = {
    external_id: element.dataset.partnerId,
    name: element.dataset.partnerName,
    email: element.dataset.partnerEmail,
    logo: element.dataset.partnerLogo,
    website: element.dataset.partnerWebsite,
    description: element.dataset.partnerDescription
  };
  var customData = {};
  Object.keys(element.dataset).forEach(function (key) {
    if (key.indexOf('partnerCustom') === 0) {
      var customKey = cleanCustomDataKey(key);
      customData[customKey] = element.dataset[key];
    }
  });
  if (Object.keys(customData).length) {
    data.custom_data = customData;
  }
  var fetchOptions = {
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    method: 'POST'
  };
  var endpoint = (0, _utilities.getPoloUrl)("/campaigns/".concat(campaignID, "/participants/"));
  var participantPromise = (0, _fetch.fetch)(endpoint, fetchOptions);
  renderLoadedStateForWidget(element);
  return participantPromise.then(function (response) {
    return response.json();
  }).then(function (responseData) {
    renderLoadedStateForWidget(element, true, responseData);
  })["catch"](function (reason) {
    (0, _utilities.log)('error', "Dashboard widget request failed: ".concat(reason, "."));
  });
}
function renderPartnerDashboardButtons() {
  var widgetElems = document.getElementsByClassName('feathr-dashboard-widget');
  for (var index = 0; index < widgetElems.length; index += 1) {
    var element = widgetElems[index];
    if (element.dataset && element.dataset.campaignId && element.dataset.partnerId) {
      if (!element.dataset.state) {
        renderPartnerDashboardButton(element);
      }
    } else {
      (0, _utilities.log)("Element ".concat(element, " is missing necessary configuration."));
    }
  }
}
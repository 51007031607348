"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.symbol.to-primitive.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.from.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.date.to-primitive.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.facebookLinkClicks = facebookLinkClicks;
exports.facebookPixel = void 0;
exports.facebookSprinkle = facebookSprinkle;
exports.renderFacebookPixelCode = renderFacebookPixelCode;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
var _promise = _interopRequireDefault(require("core-js-pure/features/promise"));
var _utilities = require("./utilities");
var _excluded = ["rfr"];
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
var standardEvents = ['ViewContent', 'Search', 'AddToCart', 'AddToWishlist', 'InitiateCheckout', 'AddPaymentInfo', 'Purchase', 'Lead', 'CompleteRegistration'];
var fbqResolve;
var fbqReject;
var facebookPixel = exports.facebookPixel = new _promise["default"](function (resolve, reject) {
  fbqResolve = resolve;
  fbqReject = reject;
});
function facebookPixelOnLoad() {
  if (window.fbq) {
    return fbqResolve(window.fbq);
  }
  return fbqReject();
}
function renderFacebookPixelCode(accountId) {
  var devMode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var pixelKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'feathr';
  if (!(0, _utilities.isObjectId)(accountId)) {
    return null;
  }
  (0, _utilities.log)("render facebook pixel for ".concat(accountId));
  return (0, _utilities.addOnLoadEventListener)(function () {
    (0, _utilities.log)('render facebook pixel load event callback');
    var pixel = window.document.createElement('script');
    pixel.setAttribute('async', 'true');
    pixel.type = 'text/javascript';
    pixel.onload = facebookPixelOnLoad;
    pixel.src = "".concat((0, _utilities.getPoloUrl)("/accounts/".concat(accountId, "/integrations/facebook/pixel.js"), 1, devMode), "?pk=").concat(pixelKey);
    (0, _utilities.head)().appendChild(pixel);
  });
}
function formatEventName(name) {
  if (!name || typeof name !== 'string') return '';
  if (name === 'page_view') {
    return 'ViewContent';
  }
  return name.split('_').map(function (n) {
    return n.charAt(0).toUpperCase() + n.slice(1);
  }).join('');
}
function facebookSprinkle() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var state = arguments.length > 1 ? arguments[1] : undefined;
  var devMode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var trackType = 'trackSingle';
  var eventName = formatEventName(params.flvr);
  if (!standardEvents.includes(eventName)) {
    trackType = 'trackSingleCustom';
  }
  if (eventName === 'Purchase') {
    if (params.value === undefined || params.currency === undefined) {
      (0, _utilities.log)('error', 'sprinkling `purchase` requires both `value` and `currency` parameters');
      return false;
    }
  }
  _promise["default"].all([facebookPixel, state.facebookPixelId]).then(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      fbq = _ref2[0],
      pId = _ref2[1];
    if (!devMode) {
      var rfr = params.rfr,
        restParams = _objectWithoutProperties(params, _excluded);
      (0, _utilities.log)("Calling fbq(".concat(trackType, ", ").concat(pId, ", ").concat(eventName, ", ").concat(JSON.stringify(restParams), ")"));
      fbq(trackType, pId, eventName, restParams);
    }
  })["catch"](function () {
    (0, _utilities.log)('error', 'facebook pixel failed to load');
  });
  return true;
}
function facebookLinkClicks() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var state = arguments.length > 1 ? arguments[1] : undefined;
  var devMode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!window.document) {
    return false;
  }
  var links = _toConsumableArray(window.document.getElementsByTagName('a'));
  links.forEach(function (link) {
    (0, _utilities.addElementEventListener)(link, 'click', function (event) {
      if (!event.target || !event.target.href) {
        return;
      }
      _promise["default"].all([facebookPixel, state.facebookPixelId]).then(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
          fbq = _ref4[0],
          pId = _ref4[1];
        var clickParams = _objectSpread(_objectSpread({}, params), {}, {
          trgt: event.target.href,
          flvr: 'page_link_click'
        });
        if (!devMode) {
          (0, _utilities.log)("Calling fbq('trackSingle', ".concat(pId, ", 'ViewContent', ").concat(JSON.stringify(clickParams), ")"));
          fbq('trackSingle', pId, 'ViewContent', clickParams);
        }
      })["catch"](function () {
        (0, _utilities.log)('error', 'facebook pixel failed to load');
      });
    });
  });
  return true;
}
"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.symbol.to-primitive.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.date.to-primitive.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addElementEventListener = addElementEventListener;
exports.addOnLoadEventListener = addOnLoadEventListener;
exports.base64EncodeUrl = base64EncodeUrl;
exports.buildUrl = buildUrl;
exports.checkUriMatch = checkUriMatch;
exports.deleteCookie = deleteCookie;
exports.encodeCustomParams = encodeCustomParams;
exports.encodeMessage = encodeMessage;
exports.encodeUtmParams = encodeUtmParams;
exports.encodeValue = encodeValue;
exports.getCookie = getCookie;
exports.getDate = getDate;
exports.getDefaultParameters = getDefaultParameters;
exports.getPoloUrl = getPoloUrl;
exports.getUrl = getUrl;
exports.getWindowParameters = getWindowParameters;
exports.head = head;
exports.isArray = isArray;
exports.isNewPixel = isNewPixel;
exports.isObject = isObject;
exports.isObjectId = isObjectId;
exports.isPromise = isPromise;
exports.log = log;
exports.normalizeUrl = normalizeUrl;
exports.parseUri = parseUri;
exports.readLog = readLog;
exports.reservedKeys = void 0;
exports.serialize = serialize;
exports.setCookie = setCookie;
exports.ttdMatchUrl = ttdMatchUrl;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.search.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url-search-params.js");
var _promise = _interopRequireDefault(require("core-js-pure/features/promise"));
var _bsonObjectid = _interopRequireDefault(require("bson-objectid"));
var _btoa = _interopRequireDefault(require("core-js-pure/stable/btoa"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/* eslint-disable no-console */
function isArray(obj) {
  if (Array.isArray) {
    return Array.isArray(obj);
  }
  return Object.prototype.toString.call(obj) === '[object Array]';
}
function isObject(obj) {
  return _typeof(obj) === _typeof({});
}
function isPromise(obj) {
  if (!obj) {
    return false;
  }
  return typeof obj.then === 'function';
}
var logs = [];
function log() {
  if (arguments.length === 1) {
    if (isObject(arguments.length <= 0 ? undefined : arguments[0])) {
      var logOptions = _objectSpread({}, arguments.length <= 0 ? undefined : arguments[0]);
      logs.push(logOptions);
    } else {
      logs.push({
        level: 'log',
        message: arguments.length <= 0 ? undefined : arguments[0]
      });
    }
  } else if (arguments.length === 2) {
    logs.push({
      level: arguments.length <= 0 ? undefined : arguments[0],
      message: arguments.length <= 1 ? undefined : arguments[1]
    });
  }
}
function readLog() {
  logs.forEach(function (logObject) {
    try {
      window.console[logObject.level](logObject.message);
    } catch (error) {
      window.console.log(logObject.message);
    }
  });
}
function base64EncodeUrl(str) {
  var b64 = (0, _btoa["default"])(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode("0x".concat(p1));
  }));
  return b64.replace(/\+/g, '-').replace(/\//g, '_');
}
function head() {
  var elements = [(window.document.getElementsByTagName('head') || [null])[0], (window.document.getElementsByTagName('body') || [null])[0]];
  return elements.filter(Boolean)[0];
}
function isNewPixel(feathrBoomerang) {
  try {
    var majorVersion = Number(feathrBoomerang.version.split('.')[0]);
    return majorVersion >= 1;
  } catch (error) {
    return false;
  }
}
function isObjectId(idString) {
  try {
    return /^([a-f0-9]{24})$/.test(idString);
  } catch (error) {
    return false;
  }
}
function getDate(factor) {
  var date = new Date();
  if (factor) {
    date.setTime(date.getTime() + 3600000 * factor);
  }
  var year = String(date.getUTCFullYear());
  var month = date.getUTCMonth();
  if (month < 10) {
    month = "0".concat(month);
  }
  var day = date.getUTCDate();
  if (day < 10) {
    day = "0".concat(day);
  }
  return [year, month, day].join('');
}
function normalizeUrl(url) {
  return url.toLowerCase();
}
function parseUri(input) {
  var matched = /^(?:(?![^:@]+:[^:@/]*@)([^:/?#.]+):)?(?:\/\/)?((?:(([^:@]*):?([^:@]*))?@)?([^:/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#/]*\.[^?#/.]+(?:[?#]|$)))*\/?)?([^?#/]*))(?:\?([^#]*))?(?:#(.*))?)/.exec(input);
  var parts = ['source', 'protocol', 'authority', 'userInfo', 'user', 'password', 'host', 'port', 'relative', 'path', 'directory', 'file', 'query', 'anchor'];
  var parsed = {};
  parts.forEach(function (part, index) {
    parsed[part] = matched[index] || '';
  });
  parsed.queryKey = {};
  parsed.query.replace(/(?:^|&)([^&=]*)=?([^&]*)/g, function (match, qkey, qvalue) {
    if (qkey) {
      parsed.queryKey[qkey] = qvalue;
    }
  });
  return parsed;
}
function encodeCustomParams(dataPoints, params) {
  log("Call to generate custom parameters with dataPoints: ".concat(JSON.stringify(dataPoints), " and params: ").concat(JSON.stringify(params)));
  var encodedParams = null;
  var userCustomParams = {};

  // Shim / kludge to support old type custom parameters
  if (window.feathr_cust_params) {
    userCustomParams = _objectSpread({}, window.feathr_cust_params);
  } else {
    // Process the params object against the reserved dataPoints to see
    // which of the passed params are custom.
    Object.keys(params).forEach(function (key) {
      if (!dataPoints.includes(key)) {
        userCustomParams[key] = params[key];
      }
    });
  }
  try {
    encodedParams = JSON.stringify(userCustomParams);
  } catch (e) {
    log("Error stringifying custom parameters: ".concat(e));
    return encodedParams;
  }
  try {
    encodedParams = base64EncodeUrl(encodedParams);
  } catch (e) {
    log("Error encoding custom parameters ".concat(e));
    return encodedParams;
  }
  return encodedParams;
}
function encodeUtmParams(utmDataPoints, queryParams) {
  log("Call to generate utm parameters with utmDataPoints: ".concat(JSON.stringify(utmDataPoints), " and queryString: ").concat(JSON.stringify(queryParams)));
  var encodedParams = null;
  var userUtmParams = {};
  try {
    Object.keys(queryParams).forEach(function (key) {
      if (utmDataPoints.includes(key.toLowerCase())) {
        userUtmParams[key] = queryParams[key];
      }
    });
  } catch (error) {
    log("Error objectifying utm parameters: ".concat(error));
    return encodedParams;
  }
  if (Object.keys(userUtmParams).length) {
    try {
      encodedParams = JSON.stringify(userUtmParams);
    } catch (e) {
      log("Error stringifying utm parameters: ".concat(e));
      return encodedParams;
    }
    try {
      encodedParams = base64EncodeUrl(encodedParams);
    } catch (e) {
      log("Error encoding utm parameters: ".concat(e));
      return encodedParams;
    }
  }
  return encodedParams;
}
function encodeMessage(message) {
  if (!message) {
    return null;
  }
  var encodedMessage = null;
  try {
    encodedMessage = base64EncodeUrl(message);
  } catch (e) {
    log("Error encoding message: ".concat(e));
  }
  return encodedMessage;
}
function encodeValue(value) {
  if (!value) {
    return null;
  }
  var encodedValue = null;
  try {
    encodedValue = base64EncodeUrl(JSON.stringify(value));
  } catch (e) {
    log("Error encoding value: ".concat(e));
  }
  return encodedValue;
}
function serialize(queryParams) {
  var combined = [];
  queryParams.forEach(function (param) {
    combined.push(param.join('='));
  });
  if (combined.length) {
    return combined.join('&');
  }
  return '';
}
function buildUrl(url, queryParams) {
  var serialized = serialize(queryParams);
  var queryStartIndex = url.indexOf('?');
  if (!serialized.length) {
    return url;
  } else if (queryStartIndex === url.length - 1) {
    return "".concat(url).concat(serialized);
  } else if (queryStartIndex !== -1) {
    if (url[url.length - 1 === '&']) {
      return "".concat(url).concat(serialized);
    }
    return "".concat(url, "&").concat(serialized);
  }
  return "".concat(url, "?").concat(serialized);
}
function getCookie(key) {
  var allCookies = window.document.cookie;
  var cookieValue = null;
  var cookieKeyIndex = allCookies.indexOf("".concat(key, "="));
  if (cookieKeyIndex !== -1) {
    var cookieValueIndex = cookieKeyIndex + key.length + 1;
    var cookieEndIndex = allCookies.indexOf(';', cookieValueIndex);
    if (cookieEndIndex === -1) {
      cookieEndIndex = allCookies.length;
    }
    cookieValue = allCookies.substring(cookieValueIndex, cookieEndIndex);
    if (cookieValue === '') {
      cookieValue = null;
    } else {
      cookieValue = window.unescape(cookieValue);
    }
  }
  return cookieValue;
}
function setCookie(key, value, expires) {
  var expiresPart = '';
  if (expires && typeof expires === 'number') {
    var date = new Date();
    date.setTime(date.getTime() + 60 * 1000 * expires);
    var dateString = date.toGMTString();
    expiresPart = "; expires=".concat(dateString);
  }
  var domainPart = "; domain=".concat(window.location.hostname);
  var escapedValue = window.escape(value);
  window.document.cookie = "".concat(key, "=").concat(escapedValue).concat(expiresPart, "; path=/").concat(domainPart, "; SameSite=Strict; Secure");
}
function deleteCookie(key) {
  setCookie(key, '', -8760);
}
function getPoloUrl() {
  var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return "https://".concat(POLO, "/v1").concat(path);
}
function addOnLoadEventListener(listener) {
  var fast = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (fast) {
    if (/complete|loaded|interactive/.test(document.readyState)) {
      return listener();
    }
    return document.addEventListener('DOMContentLoaded', listener);
  }
  if (/complete/.test(document.readyState)) {
    return listener();
  }
  return window.addEventListener('load', listener);
}
function getWindowParameters() {
  var parameters = {
    a_id: window.feathr_account_id || null,
    p_id: window.feathr_prt_id || null,
    e_id: window.feathr_e_id || null,
    lp_id: window.feathr_lp_id || null,
    plp_id: window.feathr_plp_id || null,
    seg_id: window.feathr_seg_id || null,
    cpn_id: window.feathr_cpn_id || null,
    pcpn_id: window.feathr_pcpn_id || null,
    crv_id: window.feathr_crv_id || null,
    t_id: window.feathr_t_id || null,
    bypass_hash: window.feathr_bypass_hash || null
  };
  Object.keys(parameters).forEach(function (key) {
    if (!parameters[key]) {
      delete parameters[key];
    }
  });
  return parameters;
}
function getDefaultParameters() {
  var docElement = document.documentElement;
  var body = document.getElementsByTagName('body')[0];
  var sessionId;
  try {
    sessionId = getCookie('feathr_session_id');
  } catch (error) {
    log('error', "Caught error while trying to get session id: ".concat(error));
  }
  try {
    if (!sessionId) {
      sessionId = new _bsonObjectid["default"]().toHexString();
    }
    setCookie('feathr_session_id', sessionId, 60);
  } catch (error) {
    log('error', "Caught error while trying to set session id: ".concat(error));
  }
  var browserWidth = window.innerWidth || docElement.clientWidth || body.clientWidth;
  var browserHeight = window.innerHeight || docElement.clientHeight || body.clientHeight;
  var screenWidth = window.screen.width;
  var screenHeight = window.screen.height;
  var parameters = {
    b_w: browserWidth || null,
    b_h: browserHeight || null,
    s_w: screenWidth || null,
    s_h: screenHeight || null,
    loc_url: document.location.href || null,
    rfr: document.referrer || null,
    ses_id: sessionId || null
  };
  Object.keys(parameters).forEach(function (key) {
    if (!parameters[key]) {
      delete parameters[key];
    }
  });
  return parameters;
}
function addElementEventListener(element, event, listener) {
  if (element.addEventListener) {
    element.addEventListener(event, listener);
  } else if (element.attachEvent) {
    element.attachEvent("on".concat(event), function () {
      return listener.apply(element, [window.event]);
    });
  }
}
function trimWhitespace(str) {
  return String(str).replace(/^\s+|\s+$/g, '');
}
function trimSubstring(substr, str) {
  RegExp.escape = function (text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };
  var re = new RegExp(RegExp.escape(substr), 'gi');
  return String(str).replace(re, '');
}
function sanitizeUrl(url) {
  var sanitized = normalizeUrl(url);
  sanitized = trimWhitespace(sanitized);
  sanitized = trimSubstring('https://', sanitized);
  sanitized = trimSubstring('http://', sanitized);

  // www is a useless subdomain, only remove it if it's at the beginning
  sanitized = sanitized.replace(/^www./i, '');
  return sanitized;
}
function checkUriMatch(testUri, refUri) {
  // If one or both don't exist ...then they don't match
  if (testUri === undefined || refUri === undefined) {
    return false;
  }

  // Clean them up before taking them out
  var testUriSanitized = sanitizeUrl(testUri);
  var refUriSanitized = sanitizeUrl(refUri);

  // Might as well check
  if (testUriSanitized === refUriSanitized) {
    return true;
  }
  var refUriRegEx = refUriSanitized.replace(/\/\*/gi, '*');
  refUriRegEx = refUriRegEx.replace(/\*+/gi, '*');
  refUriRegEx = refUriRegEx.replace(/\*/gi, '@WILD@');
  RegExp.escape = function (text) {
    return text.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, '\\$&');
  };
  refUriRegEx = RegExp.escape(refUriRegEx);
  refUriRegEx = refUriRegEx.replace(/@WILD@/gi, '.*');
  var reTest = new RegExp(refUriRegEx, 'gi');
  return testUriSanitized.search(reTest) > -1;
}
function ttdMatchUrl(fId, accountId) {
  var passthrough = new URLSearchParams({
    a_id: accountId
  });
  var query = new URLSearchParams({
    ttd_pid: '6fgi4r1',
    ttd_tpi: '1',
    ttd_puid: fId,
    gdpr: '0',
    ttd_passthrough: passthrough.toString()
  });
  return "https://match.adsrvr.org/track/cmf/generic?".concat(query.toString());
}
function getUrl(url) {
  return new _promise["default"](function (resolve, reject) {
    var img = new Image();
    img.onload = function (event) {
      resolve(event);
    };
    img.onerror = function (event) {
      reject(event);
    };
    img.src = url;
    img.alt = '';
  });
}
var reservedKeys = exports.reservedKeys = ['feathr_account_id', 'feathr_e_id', 'feathr_seg_id', 'feathr_event_id', 'feathr_t_id', 'feathr_cpn_id', 'feathr_crv_id', 'feathr_pcpn_id', 'feathr_prt_id'];